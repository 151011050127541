import React from "react"
import Container from "../../components/container"
import CustomHeader from "../../components/customHeader"
import SideBarView from "../../components/SideBarView"
import Seo from "../../components/Seo"
import { Typography, Grid } from "@mui/material"
import { CodeBlockView } from "../../components/HelperComponents"

const programmingRoutineView = (data) => {
  return (
    <div style={{ marginTop: "10px", marginBottom: "15px" }}>
      <Typography variant={`h6`}>{data.title}</Typography>
      <CodeBlockView code={data.code && (data.code).trim()} language={data.language}/>
      {data.description}
    </div>
  )
}


const PythonTimestampConversions = () => {
  return (
    <Container>
      <CustomHeader title={`Python Timestamp Conversions`}/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <Typography variant={`p`}>
            Timestamps are a common way to represent time in computer programming, and Python has built-in functionality
            for working with them. Timestamps are typically represented as the number of seconds since a specific epoch,
            which is January 1, 1970, 00:00:00 UTC (Coordinated Universal Time).
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            In Python, the standard library provides the <b>datetime</b> module, which includes classes for working with
            dates
            and times. The <b>datetime</b> module provides several ways to convert timestamps to and from various
            formats.
          </Typography>
          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting Unix Timestamps to Datetime Objects
          </Typography>
          <Typography variant={`p`}>
            Unix timestamps are a common format for expressing time as a number of seconds elapsed since January 1, 1970
            (also known as the "epoch"). In Python, you can convert a Unix timestamp to a <b>datetime</b> object using
            the
            datetime module.
          </Typography>

          {programmingRoutineView({
            language: `python`,
            code: `import datetime

unix_timestamp = 1615531000 # March 12, 2021 12:50:00 PM UTC

datetime_object = datetime.datetime.fromtimestamp(unix_timestamp)

print(datetime_object) # 2021-03-12 12:50:00`
          })}
          <Typography variant={`p`}>
            The <b>fromtimestamp()</b> method takes a Unix timestamp as input and returns a datetime object representing
            the
            corresponding date and time.
          </Typography>
          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting Datetime Objects to Unix Timestamps
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Conversely, you can also convert a datetime object to a Unix timestamp using the <b>timestamp()</b> method.
          </Typography>
          {programmingRoutineView({
            language: `python`,
            code: `import datetime

datetime_object = datetime.datetime(2021, 3, 12, 12, 50, 0)

unix_timestamp = datetime_object.timestamp()

print(unix_timestamp) # 1615531000.0`
          })}
          <Typography variant={`p`}>
            The <b>timestamp()</b> method returns the Unix timestamp corresponding to the datetime object.
          </Typography>
          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting ISO-formatted Timestamps to Datetime Objects
          </Typography>
          <Typography variant={`p`}>
            ISO-formatted timestamps are a standardized way of representing time in a human-readable format. In Python,
            you can convert an ISO-formatted timestamp to a <b>datetime</b> object using the datetime module.
          </Typography>
          {programmingRoutineView({
            language: `python`,
            code: `import datetime

iso_timestamp = "2021-03-12T12:50:00Z"

datetime_object = datetime.datetime.fromisoformat(iso_timestamp)

print(datetime_object) # 2021-03-12 12:50:00+00:00`
          })}
          <Typography variant={`p`}>
            The <b>fromisoformat()</b> method takes an ISO-formatted timestamp as input and returns a datetime object
            representing the corresponding date and time.
          </Typography>
          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting Datetime Objects to ISO-formatted Timestamps
          </Typography>
          <Typography variant={`p`}>
            Similarly, you can convert a datetime object to an ISO-formatted timestamp using
            the <b>isoformat()</b> method.
          </Typography>
          {programmingRoutineView({
            language: `python`,
            code: `import datetime

datetime_object = datetime.datetime(2021, 3, 12, 12, 50, 0)

iso_timestamp = datetime_object.isoformat()

print(iso_timestamp) # 2021-03-12T12:50:00`
          })}
          <Typography variant={`p`}>
            The <b>isoformat()</b> method returns the ISO-formatted timestamp corresponding to the datetime object.
          </Typography>

          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Understanding Timezones
          </Typography>
          <Typography variant={`p`}>
            Before diving into timezone conversions, it's important to understand what a timezone is. A timezone is a
            region of the world that observes the same standard time. Timezones are generally identified by a unique
            identifier, such as "UTC" (Coordinated Universal Time), "EST" (Eastern Standard Time), or "CET" (Central
            European Time).
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Each timezone is typically represented as an offset from Coordinated Universal Time (UTC), which is the
            standard time used by the international community. For example, Pacific Standard Time (PST) is 8 hours
            behind UTC, while Central European Time (CET) is 1 hour ahead of UTC.
          </Typography>

          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Using the pytz Library
          </Typography>
          <Typography variant={`p`}>
            The <b>pytz</b> library provides a simple way to work with timezones in Python. To use <b>pytz</b>, you must
            first import the library and create a timezone object:
          </Typography>
          {programmingRoutineView({
            language: `python`,
            code: `import pytz

timezone = pytz.timezone('US/Pacific')`
          })}
          <Typography variant={`p`}>
            In this example, we create a timezone object representing Pacific Standard Time (PST).
          </Typography>

          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting Timezones
          </Typography>
          <Typography variant={`p`}>
            Once you have a timezone object, you can use it to convert datetime objects between timezones.
            The <b>datetime</b>
            module provides several methods for working with timezones,
            including <b>localize()</b> and <b>astimezone()</b>.
          </Typography>
          {programmingRoutineView({
            language: `python`,
            code: `import datetime
import pytz

datetime_object = datetime.datetime(2021, 3, 12, 12, 50, 0)

utc_timezone = pytz.timezone('UTC')
pst_timezone = pytz.timezone('US/Pacific')

datetime_object_utc = utc_timezone.localize(datetime_object)
datetime_object_pst = datetime_object_utc.astimezone(pst_timezone)

print(datetime_object_utc) # 2021-03-12 12:50:00+00:00
print(datetime_object_pst) # 2021-03-12 04:50:00-08:00`
          })}
          <Typography variant={`p`}>
            In this example, we first create a datetime object representing March 12, 2021. We then create timezone
            objects for both UTC and Pacific Standard Time. We use the <b>localize()</b> method to set the timezone of
            the
            datetime object to UTC, and then use the <b>astimezone()</b> method to convert the datetime object to
            Pacific
            Standard Time.
          </Typography>

          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Handling Ambiguous Times
          </Typography>
          <Typography variant={`p`}>
            In some cases, a timestamp may correspond to an ambiguous time. For example, when daylight saving time ends
            and the clock is set back by an hour, there is a period of time where the same local time occurs twice. To
            handle ambiguous times, the pytz library provides the <b>is_ambiguous()</b> and <b>normalize()</b> methods.
          </Typography>

          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Using is_ambiguous()
          </Typography>
          <Typography variant={`p`}>
            The <b>is_ambiguous()</b> method takes a datetime object and returns <b>True</b> if the time is ambiguous in
            the given
            timezone. Here's an example:
          </Typography>
          {programmingRoutineView({
            language: `python`,
            code: `import datetime
import pytz

datetime_object = datetime.datetime(2021, 11, 7, 1, 30, 0)

pst_timezone = pytz.timezone('US/Pacific')
datetime_object_pst = pst_timezone.localize(datetime_object, is_dst=None)

if pst_timezone.is_ambiguous(datetime_object_pst):
    print("The time is ambiguous.")
else:
    print("The time is not ambiguous.")`
          })}
          <Typography variant={`p`}>
            In this example, we create a datetime object for November 7, 2021 at 1:30 AM. We then create a timezone
            object for Pacific Standard Time and use the <b>localize()</b> method to set the timezone of the datetime
            object to
            Pacific Standard Time. We pass <b>is_dst=None</b> to indicate that we don't know whether the time is during
            daylight saving time or not.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            We then use the <b>is_ambiguous()</b> method to check whether the time is ambiguous in Pacific Standard
            Time. Since
            this time falls during the daylight saving time transition, the method will return <b>True</b>.
          </Typography>
          <br/>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Using normalize()
          </Typography>
          <Typography variant={`p`}>
            The <b>normalize()</b> method takes a datetime object and returns a normalized datetime object, taking into
            account
            the ambiguity of the time in the given timezone. Here's an example:
          </Typography>
          {programmingRoutineView({
            language: `python`,
            code: `import datetime
import pytz

datetime_object = datetime.datetime(2021, 11, 7, 1, 30, 0)

pst_timezone = pytz.timezone('US/Pacific')
datetime_object_pst = pst_timezone.localize(datetime_object, is_dst=None)

if pst_timezone.is_ambiguous(datetime_object_pst):
    datetime_object_pst = pst_timezone.normalize(datetime_object_pst)

print(datetime_object_pst)`
          })}
          <Typography variant={`p`}>
            In this example, we create a datetime object and a timezone object for Pacific Standard Time, just as
            before. We use the <b>localize()</b> method to set the timezone of the datetime object to Pacific Standard
            Time,
            but this time we pass <b>is_dst=None</b> to indicate that we don't know whether the time is during daylight
            saving
            time or not.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            We then check whether the time is ambiguous using the <b>is_ambiguous()</b> method. If it is ambiguous, we
            use the
            <b>normalize()</b> method to get a normalized datetime object. The <b>normalize()</b> method will adjust the
            datetime
            object to the first occurrence of the ambiguous time.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            In this case, since November 7, 2021 at 1:30 AM is ambiguous in Pacific Standard Time,
            the <b>normalize()</b> method will adjust the datetime object to the first occurrence of that time, which is November 7, 2021 at
            1:30 AM PDT (Pacific Daylight Time). The resulting datetime object will be normalized and unambiguous.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PythonTimestampConversions

export const Head = () => {
  return (
    <Seo
      title="Mastering Timestamp Conversions in Python: A Comprehensive Guide"
      canonicalUrl={"https://www.epochconverter.io/blogs/python-timestamp-conversions"}
    />
  )
}
